/* :( */
/* @import '~@ms/medical-solutions-theme/dist/styles/main.css'; */

@import url('https://fonts.googleapis.com/css?family=Caveat|Montserrat:300,400,400i,500,600&display=swap');

/* BASIC RESET */
html {
    box-sizing: border-box;
    font-size: 16px;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
    margin: 0;
    padding: 0;
    font-weight: normal;
}

ol,
ul {
    list-style: none;
}

img {
    max-width: 100%;
    height: auto;
}
/* END RESET */

body {
    background-color: #fafbfc;
}

/* theme */
html,
body {
    overflow-x: hidden;
    max-width: 100%;
}

html {
    height: -webkit-fill-available;
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 1.5em;
    line-height: 1.6;
    font-weight: 400;
    min-height: 100vh;
    min-height: -webkit-fill-available;
    letter-spacing: rem-calc(-0.1);
}

button {
    letter-spacing: rem-calc(-0.1);
}

a {
    color: #263349;
    text-decoration: none;
    background-color: transparent;
}
